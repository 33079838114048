/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import coupleParkBench from '../images/heroes/couple-park-bench.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  List,
  ListItem,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-aet-medicareidcards']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Medicare ID Card | Call 855-200-5725 for any questions | Aetna Medicare',
          description:
            'Everything you need to know about your Medicare Card from what it is for to how you can get a new one. Call 855-200-5725 for more info today!',
        },
        path: '/medicare-card',
        promoCode: '120777',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Medicare ID Card | Call 855-200-5725 for any questions | Aetna Medicare',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero background-image-position-top"
              image={
                <img
                  src={coupleParkBench}
                  alt="elderly couple sitting on park bench and looking at mobile phone"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">Medicare ID cards </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-card.svg"
                alt="Medicare card icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                Why Do I Need a Medicare ID Card?
              </Typography>
              <Typography variant="body">
                Your Medicare ID card, sometimes called your “red, white, and
                blue” card, proves that you are enrolled in Medicare{' '}
                <a href="/parta">Part A</a>,{' '}
                <a href="/medicare-part-b">Part B</a>, or both. It’s what you
                use to gain access to Medicare-covered health services and
                medical equipment.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-mailbox.svg"
                alt="mailbox icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                How Do I Get My Medicare ID Card?
              </Typography>
              <Typography variant="body">
                Your Medicare ID card, sometimes called your “red, white, and
                blue” card, proves that you are enrolled in Medicare{' '}
                <a href="/parta">Part A</a>,{' '}
                <a href="/medicare-part-b">Part B</a>, or both. It’s what you
                use to gain access to Medicare-covered health services and
                medical equipment.
              </Typography>
              <Accordion
                items={[
                  {
                    title: 'If you’re receiving retirement benefits',
                    content: (
                      <>
                        <Typography>
                          If you’re already receiving Social Security or
                          Railroad Retirement Board (RRB) benefits, you should
                          receive your red, white, and blue Medicare card
                          roughly three months before you turn 65. You don’t
                          have to do anything to get your card. As long as your
                          address is up-to-date with the Social Security
                          Administration or RRB, you’ll receive it in the mail
                          automatically! If you qualify for Social Security
                          benefits, you’re automatically enrolled in Medicare
                          Part A (hospital insurance). However, to get Medicare
                          Part B (medical insurance) coverage when you turn 65,
                          you will need to enroll in Medicare Part B at the same
                          time as you apply for retirement benefits.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'If you’re receiving disability benefits',
                    content: (
                      <>
                        <Typography>
                          If you’ve been receiving Social Security or RBB
                          disability benefits for 24 consecutive months, you’ll
                          be automatically enrolled in Medicare, and you’ll
                          receive your Medicare ID card in the mail 25 months
                          after you received your first Social Security check.
                        </Typography>
                        <Typography>
                          For those who have amyotrophic lateral sclerosis (ALS
                          or Lou Gehrig’s disease), you’ll be automatically
                          enrolled in Medicare the same month your disability
                          benefits begin and should receive your card shortly
                          after that. This is the case regardless of your age.
                        </Typography>
                        <Typography>
                          And if you suffer from end-stage renal disease (ESRD),
                          you’ll need to manually{' '}
                          <a href="/">enroll in Medicare</a> to receive your
                          Medicare ID card.
                        </Typography>
                      </>
                    ),
                  },
                ]}
              />
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-65.svg"
                alt="65 icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                What if I’m 65 but not receiving retirement benefits yet?
              </Typography>
              <Typography variant="body">
                Not everyone is automatically enrolled in Medicare. If you’re
                getting close to 65 and aren’t yet receiving retirement
                benefits, you’ll need to manually enroll in Medicare Part A or
                Part B during your Initial Enrollment Period (IEP).
              </Typography>
              <Typography variant="body">
                Your IEP starts three months before the month you turn 65 and
                ends three months after. If you’re approved, you should receive
                your Medicare card in the mail within 30 days.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-card-plus.svg"
                alt="card-plus icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                How do I request a replacement Medicare card?
              </Typography>
              <Typography variant="body">
                If you’ve lost your Medicare ID card, or if it was damaged or
                stolen, you can request a new card by using one of the following
                methods:
              </Typography>
              <Accordion
                items={[
                  {
                    title: 'Request a new Medicare card online',
                    content: (
                      <>
                        <Typography>
                          The fastest and easiest way to get a replacement card
                          is to simply visit the Social Security
                          Administration’s{' '}
                          <a
                            href="https://faq.ssa.gov/en-US/Topic/article/KA-01735"
                            target="_blank"
                          >
                            website
                          </a>
                          . Keep in mind, you’ll probably need to provide the
                          following information:
                        </Typography>
                        <List>
                          <ListItem>
                            Your full legal name as it appears on your Medicare
                            ID card
                          </ListItem>
                          <ListItem>
                            Your Social Security number (even if you receive
                            benefits through your spouse)
                          </ListItem>
                          <ListItem>
                            Your address and contact info, including your phone
                            number or email address
                          </ListItem>
                        </List>
                        <Typography>
                          You may also be asked a number of questions to verify
                          your info.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'Call Social Security directly',
                    content: (
                      <>
                        <Typography>
                          You can call the Social Security Administration
                          directly to request a new Medicare ID card at{' '}
                          <a href="tel:1-800-722-1213">1-800-722-1213</a>.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'Visit your local Social Security office',
                    content: (
                      <>
                        <Typography>
                          You can visit your nearest Social Security
                          Administration office and request a replacement card
                          in person, too. To find your local office, use the{' '}
                          <a
                            href="https://secure.ssa.gov/ICON/main.jsp"
                            target="_blank"
                          >
                            Social Security Office Locator tool
                          </a>
                          .
                        </Typography>
                      </>
                    ),
                  },
                ]}
              />
              <Typography variant="h4" color="primary">
                What if I’m outside the US?
              </Typography>
              <Typography variant="body">
                If you are living or traveling outside the US, you can still
                request a replacement Medicare Card online or by phone or you
                can contact the nearest{' '}
                <a
                  href="https://www.ssa.gov/foreign/index.html"
                  target="_blank"
                >
                  US consulate or embassy
                </a>
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-clock.svg"
                alt="clock icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                How Long Does It Take to Get My Replacement Medicare Card?
              </Typography>
              <Typography variant="body">
                Your new Medicare ID card should arrive in the mail in about 30
                days. If, however, you need health care services or equipment
                sooner than that, you can request a temporary letter as proof of
                enrollment in Medicare. To get a temporary letter of proof,
                you’ll need to visit your local Social Security Administration
                office in person.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-2-cards.svg"
                alt="Medicare cardx2 icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                Medicare Advantage (Part C) and prescription drug (Part D) cards
              </Typography>
              <Typography variant="body">
                Medicare Part C and Part D are health insurance plans regulated
                by the federal government that you can purchase through a
                private insurance company. People typically buy them because
                they cover services or medications that aren’t covered by
                Original Medicare.
              </Typography>
              <Typography variant="body">
                Because of this, if you’re on a Medicare Advantage (Part C) or
                prescription drug (Part D) plan, you’ll receive a different
                Medicare ID card than your Original Medicare or red, white, and
                blue card. This card will generally include your private
                insurer’s name and logo, as well as information regarding your
                coverage, and you may need to show it to health care providers
                in order to get your services or prescriptions covered by
                Medicare.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
